<template>
  <div
    class="modal box-shadow relative flex !w-full flex-col items-center font-normal text-xs"
    id="privacyPolicyModal"
  >
    <div class="prose prose-sm max-w-full text-whiteSmokeAlpha-56">
      <div class="flex w-full justify-end">
        <XMarkIcon
          class="h-5 w-5 cursor-pointer hover:scale-125"
          @click="closeModal"
        />
      </div>
      <h2 class="!mt-0 flex justify-center text-center text-gray-300">
        Terms of Use – FROSTY METRICS
      </h2>
      <div class="h-96 w-full overflow-auto md:h-80">
        <h4 class="text-gray-300">ACCEPTANCE OF THE TERMS OF USE</h4>
        <p>
          These terms of use are entered into by and between You and The Tie
          Inc. (“The Tie,” “Company,” “We,” “Our,” or “Us”). The following terms
          and conditions (collectively, “Terms of Use”), govern your access to
          and use of
          <a href="https://www.frostymetrics.com/" class="text-blue-400"
            >www.frostymetrics.com</a
          >, including any content, functionality, and services offered on or
          through such site (collectively the “Website” or “Site”), whether as a
          guest or a registered user.
        </p>
        <p>
          You represent that you (i) are of a legal age sufficient to agree to
          these Terms of Use in accordance with applicable law, (ii) have read,
          understand, and agree to be bound by these Terms of Use, and (iii)
          have all rights, permissions, and authorizations necessary to use such
          service, including applying for an Avalanche address and connecting a
          wallet to the Site.
        </p>
        <p>
          Please read the Terms of Use carefully before you start to use the
          Website. By using the Website, you accept and agree to be bound and
          abide by these Terms of Use and Our Privacy Policy, found at
          <a href="https://www.frostymetrics.com" class="text-blue-400"
            >https://www.frostymetrics.com/privacy-policy</a
          >, incorporated herein by reference. If you do not agree to these
          Terms of Use and/or the Privacy Policy, you must not access or use the
          Website.
        </p>
        <p>
          At all times, your use of the Site is subject to the Terms of Use,
          which incorporates this Privacy Policy.
        </p>
        <h4 class="text-gray-300">INTELLECTUAL PROPERTY RIGHTS</h4>
        <p>
          The Company name, the Company logo, and all related names, logos,
          product and service names, designs, and slogans are copyrights and/or
          trademarks of the Company or its affiliates or licensors. You must not
          use such marks without the prior written permission of the Company.
        </p>
        <ul>
          <li>
            You must not reproduce, distribute, modify, create derivative works
            of, publicly display, publicly perform, republish, download, store,
            or transmit any of the Materials on Our Website, except as follows:
          </li>
          <li>
            Your computer may temporarily store copies of such Materials
            incidental to your accessing and viewing those Materials.
          </li>
          <li>
            You may store files that are automatically cached by your web
            browser for display enhancement purposes.
          </li>
          <li>
            If We provide you with the ability to use certain features (such as
            the ability to connect your wallet using an Avalanche address) you
            may take such actions as are enabled by such features.
          </li>
        </ul>
        <p>You must not:</p>
        <ul>
          <li>Modify copies of any Materials from the Website;</li>
          <li>
            Delete or alter any copyright, trademark, or other proprietary
            rights notices from copies of Materials from the Website.
          </li>
          <li>
            Access or use for any commercial purposes any part of the Website or
            any services or Materials available through the Website.
          </li>
        </ul>
        <p>
          If you wish to make any use of Materials on the Website other than as
          set out in this section, please address your request to ben@thetie.io.
        </p>
        <p>
          If you print, copy, modify, download, or otherwise use or provide any
          other person with access to any part of the Website in breach of these
          Terms of Use, your right to use the Website will cease immediately and
          you must, at Our option, return or destroy any copies of the Materials
          you have made. No right, title, or interest in or to the Website or
          any content on the Website is transferred to you, and all rights not
          expressly granted are reserved by the Company. Any use of the Website
          not expressly permitted by these Terms of Use is a breach of these
          Terms of Use and may violate copyright, trademark, and other laws.
        </p>
        <section>
          <h4 class="text-gray-300">LICENSE</h4>
          <p class="">
            The Company grants you a personal, revocable, non-exclusive,
            non-transferable, limited right and license to access the Website,
            provided that you comply with these Terms of Use. You may not
            modify, copy, distribute, broadcast, transmit, reproduce, publish,
            license, transfer, sell, scrape, mirror, or frame any Materials.
          </p>
        </section>
        <section>
          <h4 class="text-gray-300">CHANGES TO THE TERMS OF USE</h4>
          <p class="">
            We may revise and update these Terms of Use from time to time and in
            Our sole discretion. All changes are effective immediately when We
            post them and apply to all access and use of the Website thereafter.
            However, any changes to the dispute resolution provisions set forth
            under Applicable Law; Jurisdiction and Venue will not apply to any
            disputes for which the parties have actual notice on or prior to the
            date the change is posted on the Website.
          </p>
          <p class="">
            Your continued use of the Website following the posting of revised
            Terms of Use means that you accept and agree to the changes. You are
            expected to check this page frequently so you are aware of any
            changes, as they are binding upon you.
          </p>
        </section>
        <section>
          <h4 class="text-gray-300">
            ACCESSING THE WEBSITE AND ACCOUNT SECURITY
          </h4>
          <p class="">
            We reserve the right to withdraw or amend this Website, and any
            service or Materials We provide on the Website, in Our sole
            discretion without notice. We will not be liable if for any reason
            at all any part of the Website is unavailable at any time or for any
            period. From time to time, We may restrict access to some parts of
            the Website, or the entire Website, to any or all users.
          </p>
          <p class="">
            You agree that the use of any interactive features on the Website is
            governed by Our Privacy Policy,
            <a href="https://www.frostymetrics.com/" class="text-blue-400"
              >https://www.frostymetrics.com/privacy-policy</a
            >, and you consent to all actions We take with respect to your
            information consistent with Our Privacy Policy.
          </p>
        </section>
        <section>
          <h4 class="text-gray-300">USE OF THIS WEBSITE</h4>
          <p class="">
            The Website and Materials are provided solely for your own
            informational purposes. Any unauthorized use is prohibited. You
            agree not to use the Website or Materials for any purpose that is
            unlawful or prohibited by these Terms of Use, or for any other
            purpose not reasonably intended by the Company. You further agree to
            abide by all applicable local, state, national, and international
            laws and regulations.
          </p>
          <p class="py-2">
            Without limiting the generality of the foregoing, you will not, nor
            will you allow a third party to:
          </p>
          <div class="px-4">
            <p>
              (a) Copy, modify, or create derivative works of the Service, in
              whole or in part.
            </p>
            <p>
              (b) Rent, lease, lend, sell, license, sublicense, assign,
              distribute, publish, transfer, or otherwise make available the
              Service or any portion thereof.
            </p>
            <p>
              (c) Reverse engineer, disassemble, decompile, decode, adapt, or
              otherwise attempt to derive or gain access to any component of the
              Service, in whole or in part.
            </p>
            <p>(d) remove any proprietary notices from the Service.</p>
            <p>
              (e) Use any part of the Service in any manner or for any purpose
              that infringes, misappropriates, or otherwise violates any
              intellectual property right or other right of any person, or that
              violates any applicable law.
            </p>
            <p>
              (f) Combine or integrate the Service with any software,
              technology, services, or materials not authorized by Us.
            </p>
            <p>
              (g) Use the Service to store or transmit infringing, libelous, or
              otherwise unlawful or tortious material, or to store or transmit
              material in violation of third-party privacy rights.
            </p>
            <p>
              (h) Interfere with or disrupt the integrity or performance of the
              Service.
            </p>
            <p>
              (i) Copy or attempt to reproduce the Service or any part, feature,
              function, or user interface thereof.
            </p>
            <p>
              (j) Copy, extract, or store Content except as expressly permitted.
              or
            </p>
            <p>
              (k) Access the Service in order to build or enhance a competitive
              Service or service.
            </p>
          </div>
        </section>
        <section>
          <h4 class="text-gray-300">SCORES AND RECOMMENDATIONS</h4>
          <p class="">
            If you chose to enter your wallet address, Our Website may make
            provide a “score” to you based on your use of the Avalanche
            ecosystem relative to other users. This score is provided solely for
            your information and, if you opt to do so, may be shared with others
            on Twitter “X” or otherwise. We may also provide recommendations to
            you based upon your use of the Avalanche ecosystem and your score.
            These recommendations may contain links to third-party websites,
            services, or resources that We do not own or control. Both the
            recommendations and the links, if any, are provided for your
            convenience. No endorsement of any third-party products, services,
            or information is expressed or implied by any information, material,
            or content of any third party contained in, referred to, included
            on, or linked from or to Our Website. Any information, data,
            opinions, recommendations, products, or services provided by such
            third parties through links to other websites or otherwise made
            available through their websites are solely those of such third
            parties. We have no control over, and assume no responsibility for,
            the content, privacy policies, or practices of any third-party
            websites or services. We strongly advise you to read the terms and
            conditions and privacy policies of any third-party websites or
            services you visit.
          </p>
        </section>
        <section>
          <h4 class="text-gray-300">DISCLAIMER OF WARRANTIES</h4>
          <p class="">
            OTHER THAN AS EXPRESSLY SET OUT HEREIN, OUR WEBSITE AND MATERIALS
            ARE PROVIDED ON AN “AS IS'' AND “AS AVAILABLE” BASIS, WITH ALL
            FAULTS, AND WITHOUT WARRANTY OF ANY KIND. THE COMPANY AND ITS
            SUPPLIERS SPECIFICALLY DISCLAIM ALL WARRANTIES OF ANY KIND, EITHER
            EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, ANY
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            QUIET ENJOYMENT, QUALITY OF INFORMATION, NON-INFRINGEMENT, AND
            TITLE. NO WARRANTY IS MADE THAT OUR WEBSITE WILL BE TIMELY, SECURE,
            OR ERROR-FREE. IN JURISDICTIONS NOT ALLOWING THE LIMITATION OR
            EXCLUSION OF CERTAIN WARRANTIES, THE COMPANY'S WARRANTY SHALL BE
            LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
          </p>
          <h4 class="text-gray-300">LIMITATION OF LIABILITY</h4>
          <p class="">
            All information on Our Website is provided free-of-charge, and by
            accessing Our Website, you acknowledge that it would be unreasonable
            to hold Us liable with respect to Our Website and the information
            therein contained.
          </p>
          <p class="">
            REGARDLESS OF THE TYPE OF CLAIM OR THE NATURE OF THE CAUSE OF
            ACTION, TO THE EXTENT ALLOWED BY LAW, YOU UNDERSTAND AND EXPRESSLY
            AGREE THAT IN NO EVENT WILL THE COMPANY OR ITS AFFILIATES, OFFICERS,
            EMPLOYEES, AGENTS OR LICENSORS BE LIABLE FOR: (I) ANY INDIRECT,
            SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES,
            INCLUDING, BUT NOT LIMITED TO, LOSS OF REVENUES, DATA AND PROFITS,
            OR OTHER INTANGIBLE LOSSES, EVEN IF THE COMPANY HAS BEEN ADVISED AS
            TO THE POSSIBILITY OF SUCH DAMAGES; OR (II) TOTAL CUMULATIVE
            LIABILITY ARISING UNDER OR RELATED TO OUR WEBSITE, WHETHER IN
            CONTRACT, TORT, OR OTHERWISE. SOME JURISDICTIONS DO NOT ALLOW THE
            LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
            DAMAGES. IN THOSE JURISDICTIONS, THE COMPANY'S LIABILITY SHALL BE
            LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW. YOU UNDERSTAND AND
            AGREE THAT YOUR USE OF OUR WEBSITE IS PREDICATED UPON YOUR WAIVER OF
            ANY RIGHT TO SUE THE COMPANY, ITS OWNERS OR ITS AFFILIATES DIRECTLY,
            OR TO PARTICIPATE IN A CLASS ACTION SUIT FOR ANY LOSSES OR DAMAGES
            RESULTING FROM YOUR USE OF OUR WEBSITE.
          </p>
          <p class="">
            IN ALL CASES, NEITHER THE COMPANY NOR ITS SUPPLIERS ASSUME ANY
            RESPONSIBILITY OR LIABILITY FOR ANY ERRORS OR OMISSIONS ON OUR
            WEBSITE OR WITH RESPECT TO WEBSITE MATERIALS, ANY FAILURES, DELAYS,
            OR INTERRUPTIONS IN OUR WEBSITE'S ACCESSIBILITY, ANY LOSSES OR
            DAMAGES ARISING FROM THE USE OF OUR WEBSITE OR WEBSITE MATERIALS,
            ANY CONDUCT BY OTHER USERS OF OUR WEBSITE, OR UNAUTHORIZED ACCESS TO
            OR USE OF OUR WEBSITE OR WEBSITE MATERIALS.
          </p>
        </section>
        <section>
          <h4 class="text-gray-300">APPLICABLE LAW; JURISDICTION AND VENUE</h4>
          <p class="">
            We control Our Website from Our offices within the United States. We
            make no representation that the Materials on Our Website are
            appropriate, legal, or available for use in other locations. You may
            not use or export the Materials in violation of United States export
            laws and regulations. Any claim relating to Our Website or Materials
            shall be governed by the laws of the State of New York, without
            reference to its choice of law provisions. You agree that the
            exclusive venue and jurisdiction for any controversy, dispute or
            claim arising out of or relating to Our Website or Materials shall
            be the federal and state courts located in the State of New York.
            You submit to the exclusive venue and jurisdiction of such courts,
            agree that you will not bring any suit or judicial proceeding in any
            forum other than such courts, and agree not to assert any objection
            you may have to the venue or jurisdiction of such courts. In the
            event the Company employs attorneys to enforce any right arising out
            of or relating to Our Website or Materials, you shall reimburse the
            Company its reasonable attorneys' fees and costs.
          </p>
          <h4 class="text-gray-300">SEVERABILITY</h4>
          <p class="">
            If any part of these Terms of Use is determined to be invalid or
            unenforceable pursuant to applicable law, including, but not limited
            to, the warranty disclaimers and the liability limitations set forth
            above, then the invalid or unenforceable provision will be deemed
            superseded by a valid, enforceable provision that most clearly
            matches the intent of the original provision, and the remainder of
            these Terms of Use shall continue in effect.
          </p>
          <h4 class="text-gray-300">DIGITAL MILLENNIUM COPYRIGHT ACT</h4>
          <p class="">
            If you believe that any Materials infringe your copyright, you (or
            your agent) may send Us notice to
            <span class="text-blue-400">security@thetie.io</span>
            with a copy to
            <span class="text-blue-400">ben@thetie.io</span>
            requesting that such Materials or User Contributions be removed.
            Notice must be provided in writing and must include the following
            information:
          </p>
          <ul>
            <li>
              A signature of the person authorized to act on behalf of the owner
              of the copyright interest;
            </li>
            <li>
              Identification of the copyrighted work that you claim has been
              infringed;
            </li>
            <li>
              The location of the work you claim is infringing (e.g., URL) or
              enough detail that We may find it;
            </li>
            <li>Your name, address, telephone number, and e-mail address;</li>
            <li>
              A statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law;
            </li>
            <li>
              A statement, made under penalty of perjury, that the information
              in your notice is accurate and that you are the copyright owner or
              are authorized to act on the copyright owner's behalf.
            </li>
          </ul>
        </section>
        <section>
          <h4 class="text-gray-300">CHANGES TO THE WEBSITE</h4>
          <p>
            We may update the content on the Website from time to time, but its
            content is not necessarily complete or up to date. Any of the
            Materials on the Website may be out of date at any given time, and
            We are under no obligation to update such Materials.
          </p>
        </section>
        <section>
          <h4 class="text-gray-300">
            INFORMATION ABOUT YOUR VISITS TO THE WEBSITE
          </h4>
          <p>
            All information We collect on the Website is subject to Our Privacy
            Policy. By using the Website, you consent to all actions taken by Us
            with respect to your information in compliance with Our Privacy
            Policy.
          </p>
        </section>
        <section>
          <h4 class="text-gray-300">WAIVER</h4>
          <p>
            No waiver by the Company of any term or condition set forth in these
            Terms of Use shall be deemed a further or continuing waiver of such
            term or condition or a waiver of any other term or condition, and
            any failure of the Company to assert a right or provision under
            these Terms of Use shall not constitute a waiver of such right or
            provision.
          </p>
        </section>
        <section>
          <h4 class="text-gray-300">GENERAL INQUIRIES</h4>
          <p>
            Please send general inquiries regarding intellectual property issues
            to
            <span class="text-blue-400">legal@thetie.io</span>.
          </p>
          <p>
            Copyright Agent for Copyright Infringement Claims (Digital
            Millennium Copyright Act):
          </p>
          <p>
            The designated agent to receive notification of claimed infringement
            under Title II of the Digital Millennium Copyright Act is:
            <span class="text-blue-400">legal@thetie.io</span>.
          </p>
        </section>
        <p class="pt-2">(updated 04.10.2024)</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline';
const props = defineProps({
  showModal: Boolean,
});
const emit = defineEmits(['close-modal']);

function closeModal() {
  emit('close-modal', { showModal: false });
}
</script>
