<template>
  <BaseCard>
    <div
      class="w-full overflow-hidden rounded-lg px-3 pt-0 shadow sm:space-y-4 md:pt-3"
      v-if="!showLoading"
    >
      <p
        class="items-center py-2 tracking-wider text-textColor lg:flex lg:py-0"
      >
        <span class="pr-1">
          {{ statItem.title }}
        </span>
        <span v-if="statItem.selectedFilter" class="mr-2">
          {{ statItem.selectedFilter }}
        </span>
        <Tooltip :text="tooltipText" :id="statItem.title" v-if="tooltipText">
          <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
        </Tooltip>
      </p>
      <div class="flex h-12 items-center justify-between lg:px-0">
        <div class="flex-shrink-0 items-end sm:mb-2 md:space-x-2 lg:space-x-0">
          <!-- value and percentage -->
          <div class="flex items-center">
            <span
              class="text-lg font-semibold text-neutral-100 lg:text-xs xl:text-xl"
            >
              <span v-if="statItem.usd">$</span
              >{{
                statItem.rawValue
                  ? statItem.total
                  : formatNumber(statItem.total)
              }}
              <span v-if="statItem.suffix">&nbsp;{{ statItem.suffix }}</span>
            </span>
            <span
              :class="`ml-2 rounded p-0.5 px-2 text-xs font-semibold ${
                statItem.growth >= 0
                  ? 'bg-[#2F954952]  text-[#d4f4d4]'
                  : 'bg-[#DA223452] text-[#EF7576]'
              } `"
              v-if="statItem.total > 0 && !statItem.noGrowth"
              style="font-family: 'Source Code Pro', monospace"
            >
              {{ percentage(statItem.growth) }}
            </span>
          </div>
          <!-- from -->
          <div
            class="text-xs font-medium text-[#6E87E3] md:pt-2 lg:flex lg:py-0 lg:pt-0 xl:ml-0"
            v-if="statItem.from"
          >
            <span
              >from <span v-if="statItem.usd">$</span
              >{{ formatNumber(statItem.from) }}</span
            >
          </div>
        </div>
        <LineGraphWidget
          :series="dataSeries"
          :time-format="timeFormat"
          :use-utc="useUtc"
          :line-color="statItem.growth >= 0 ? '#368849' : '#9f1239'"
          :gradiant-color="statItemGradiantColor"
          :dollar-sign="dollarSign"
          class="flex w-8/12 justify-end"
          v-if="!showLoading"
        />
      </div>
    </div>
    <MarketPalaceMiniChartCard v-if="showLoading" class="p-3" />
  </BaseCard>
</template>

<script setup>
import LineGraphWidget from './MiniLineChart.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from '../Shared/Tooltip.vue';
import { rounded, percentage, formatNumber } from '../composeables/filters.js';
import MarketPalaceMiniChartCard from '../load_templates/MarketPalaceMiniChartCard.vue';
import { computed, ref } from 'vue';
import BaseCard from '../Shared/BaseCard.vue';

const props = defineProps({
  statItem: { type: Object, required: true },
  timeFormat: String,
  showLoading: Boolean,
  useUtc: {
    type: Boolean,
    default: true,
  },
  dollarSign: {
    type: Boolean,
    default: false,
  },
  height: {
    type: String,
    default: 'md:h-44',
  },
  tooltipText: {
    type: String,
    default: '',
  },
});

const tooltipString =
  props.statItem?.tooltipText || 'TVL data provided by DeFiLlama';
const dataSeries = computed(() => {
  return props.statItem?.chart || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
});

const statItemGradiantColor = computed(() => {
  return props.statItem?.growth >= 0
    ? [
        [0, 'rgba(92, 188, 110, 0.25)'],
        [1, 'rgba(2, 10, 24, 0.25)'],
      ]
    : [
        [0, 'rgba(201, 46, 46, 0.25)'],
        [1, 'rgba(2, 6, 10, 0.25)'],
      ];
});
</script>
